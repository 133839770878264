import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-10 gx-xl-10" }
const _hoisted_2 = { class: "col-xl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTTableWidgetDailyCashFlow = _resolveComponent("KTTableWidgetDailyCashFlow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_KTTableWidgetDailyCashFlow, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-10" })
    ])
  ]))
}