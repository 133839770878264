
import { defineComponent, onMounted } from "vue";
import KTTableWidgetDailyCashFlow from "@/components/widgets/tables/WidgetDailyCashFlow.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "daily-cash-flow",
  components: {
    KTTableWidgetDailyCashFlow,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Daily Cash Flow", ["Financial Statements"]);
    });
  },
});
