
import { defineComponent } from "vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "kt-widget-daily-cash-flow",
  components: {
    Field,
    ElCurrencyInputNoSymbol,
    // ErrorMessage,
    Form,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    const months = [
      {
        value: "January",
        label: "January",
      },
      {
        value: "February",
        label: "February",
      },
      {
        value: "March",
        label: "March",
      },
      {
        value: "April",
        label: "April",
      },
      {
        value: "May",
        label: "May",
      },
      {
        value: "June",
        label: "June",
      },
      {
        value: "July",
        label: "July",
      },
      {
        value: "August",
        label: "August",
      },
      {
        value: "September",
        label: "September",
      },
      {
        value: "October",
        label: "October",
      },
      {
        value: "November",
        label: "November",
      },
      {
        value: "December",
        label: "December",
      },
    ];

    const openingBalances = {
      label: "Opening Balance",
      value: [],
    };
    const statements = {
      cashInFlow: {
        label: "Cash In Flow",
        child: {
          fromSales: {
            label: "From Sales",
            child: {
              cashSales: {
                label: "Cash Sales",
                value: [],
                total: 0,
              },
              cashAdvanceByCustomer: {
                label: "Cash Advance by Customers",
                value: [],
                total: 0,
              },
              cashbByTradeDebtors: {
                label: "Cash by Trade Debtors",
                value: [],
                total: 0,
              },
            },
          },
          fromFinancingCapital: {
            label: "From Financing/ Capital",
            child: {
              capitalContribution: {
                label: "Capital Contribution",
                value: [],
                total: 0,
              },
              loansGrantsReceivedfromBankGovernment: {
                label: "Loans/ Grants Received from Bank / Government",
                value: [],
                total: 0,
              },
              otherIncomes: {
                label: "Other Incomes / Cash Inflows",
                value: [],
                total: 0,
              },
            },
          },
        },
      },
      totalCashInFlow: {
        label: "Total Cash In Flow",
        value: [],
        total: 0,
      },
      cashOutFlow: {
        label: "Cash Outflow",
        child: {
          materialStockPurchased: {
            label: "Material/ Stock Purchased",
            value: [],
            total: 0,
          },
          marketing: {
            label: "Marketing",
            value: [],
            total: 0,
          },
          salariesEPFSOCSO: {
            label: "Salaries, EPF, SOCSO",
            value: [],
            total: 0,
          },
          rental: {
            label: "Rental",
            value: [],
            total: 0,
          },
          electricityWaterPhoneInternet: {
            label: "Electricity, Water, Phone, Internet",
            value: [],
            total: 0,
          },
          storageFeesDeliveryFees: {
            label: "Storage Fees/ Delivery Fees",
            value: [],
            total: 0,
          },
          generalExpensesOthers: {
            label: "General Expenses/ Others",
            value: [],
            total: 0,
          },
          insurance: {
            label: "Insurance",
            value: [],
            total: 0,
          },
          taxesZakat: {
            label: "Taxes/ Zakat",
            value: [],
            total: 0,
          },
          purchaseFixedAssets: {
            label:
              "Purchase of Fixed Assets (Restaurant Furnitures, Food Truck)",
            value: [],
            total: 0,
          },
          loans: {
            label:
              "Loans/ Grant Repayment (e.g. TEKUN, AIM, Microfinance, etc.)",
            value: [],
            total: 0,
          },
          personalUsageRepaymentofCapital: {
            label: "Personal Usage/ Repayment of Capital",
            value: [],
            total: 0,
          },
        },
      },
      totalCashOutFlow: {
        label: "Total Cash Out Flow",
        value: [],
        total: 0,
      },
    };

    const netCashSurpluses = {
      label: "Net Cash Surplus/ (Deficit)",
      value: [],
    };


    console.log();

    const dates = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ];
    const totalDays = 0;

    return {
      months,
      dates,
      statements,
      totalDays,
      openingBalances,
      netCashSurpluses,
    };
  },
  async mounted() {
    // this.openingBalances.value[0] = 1;
    this.totalDays = this.dates.length;
    // this.statements.forEach((value, index) => {
    // }
    this.initDays();
  },
  methods: {
    initDays() {
      for (let index = 0; index < 30; index++) {
        this.openingBalances.value.push(0);
        this.statements.totalCashInFlow.value.push(0);
        this.netCashSurpluses.value.push(0);
        this.statements.totalCashOutFlow.value.push(0);
      }
      // cash in flow
      for (let key of Object.keys(this.statements.cashInFlow.child)) {
        let cashInFlowChild = this.statements.cashInFlow.child[key].child;
        for (let keyChild of Object.keys(cashInFlowChild)) {
          for (let index = 0; index < 30; index++) {
            cashInFlowChild[keyChild].value.push(0);
          }
        }
      }
      // cash out flow
      for (let key of Object.keys(this.statements.cashOutFlow.child)) {
        let cashOutFlowChild = this.statements.cashOutFlow.child[key];
        for (
          let indexcashOutFlowChild = 0;
          indexcashOutFlowChild < 30;
          indexcashOutFlowChild++
        ) {
          cashOutFlowChild.value.push(0);
        }
      }
    },

    calculateOpeningBalance() {
      for (let i = 0; i < this.openingBalances.value.length; i++) {
        this.openingBalances.value[i] = 0;
      }

      for (let keyChild of Object.keys(this.statements.cashInFlow.child)) {
        let cashInFlowChild = this.statements.cashInFlow.child[keyChild];

        for (let cashInFlowChildSub of Object.keys(cashInFlowChild.child)) {
          let stateChildSubValue =
            cashInFlowChild.child[cashInFlowChildSub].value;

          cashInFlowChild.child[cashInFlowChildSub].total =
            stateChildSubValue.reduce((sum, item) => sum + item);

          for (let i = 1; i < this.openingBalances.value.length; i++) {
            this.openingBalances.value[i] += stateChildSubValue[i - 1];
            this.statements.totalCashInFlow.value[i - 1] =
              this.openingBalances.value[i];
          }
        }
      }

      for (let i = 0; i < this.openingBalances.value.length; i++) {
        this.netCashSurpluses.value[i] =
          this.statements.totalCashInFlow.value[i] -
          this.statements.totalCashOutFlow.value[i];

        if (i > 0) {
          this.openingBalances.value[i] =
            this.netCashSurpluses.value[i - 1] +
            this.openingBalances.value[i - 1];
        }
      }
    },

    calculateNetCashSurpluses() {
      for (let i = 0; i < this.netCashSurpluses.value.length; i++) {
        this.netCashSurpluses.value[i] = 0;
      }

      for (let keyChild of Object.keys(this.statements.cashOutFlow.child)) {
        let cashOutFlowChild = this.statements.cashOutFlow.child[keyChild];
        let stateChildSubValue = cashOutFlowChild.value;

        cashOutFlowChild.total = stateChildSubValue.reduce(
          (sum, item) => sum + item
        );

        for (let i = 0; i < this.netCashSurpluses.value.length; i++) {
          this.netCashSurpluses.value[i] += stateChildSubValue[i];
          this.statements.totalCashOutFlow.value[i] =
            this.netCashSurpluses.value[i];

          this.statements.totalCashOutFlow.total = cashOutFlowChild.total;
          console.log(this.statements.totalCashOutFlow.total);
        }
      }

      for (let i = 0; i < this.netCashSurpluses.value.length; i++) {
        this.netCashSurpluses.value[i] =
          this.statements.totalCashInFlow.value[i] -
          this.statements.totalCashOutFlow.value[i];

        if (i > 0) {
          this.openingBalances.value[i] =
            this.netCashSurpluses.value[i - 1] +
            this.openingBalances.value[i - 1];
        }
      }
    },

    onSubmit(values) {
      console.log(values);

      Swal.fire({
        title: "Congratulation!",
        text: "Calculation has been saved!",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    },
  },
});
